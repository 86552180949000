import React from 'react'
// import {  LazyLoadImage } from 'react-lazy-load-image-component';
// import 'react-lazy-load-image-component/src/effects/blur.css';
import { HashLink as Link } from "react-router-hash-link";

export default function Home() {
    return (
        <div>
            <main>
                <section className="hero-area mrg-top-all">
                    <div id="carouselExampleDark" className="carousel slide carousel-dark  bg-overlay " data-bs-ride="carousel">
                        <div className="carousel-indicators mrg">
                            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active " data-bs-interval="3000">
                            <picture>
                                    <source media="(max-width: 540px)" srcSet="/images/banner/m_home_11.webp" width="1647" height="720"/>
                                    <source media="(min-width: 541px)" srcSet="/images/banner/home_1.webp" />
                                    <img width="1920" height="720" src="/images/banner/home_1.webp"  className="d-block  carousel-image product-image" alt="..." />
                            </picture>
                                <div className="carousel-caption d-none d-md-block">

                                </div>
                            </div>
                            <div className="carousel-item" data-bs-interval="3000">
                            <picture>
                                    <source media="(max-width: 540px)" srcSet="/images/banner/m_home_21.webp" width="1641" height="720" className='m_home-21'/>
                                    <source media="(min-width: 541px)" srcSet="/images/banner/home_2.webp" />
                                    <img width="1920" height="720"  src="/images/banner/home_2.webp"  className="d-block  carousel-image " alt="..." />
                            </picture>                    
                            <div className="carousel-caption d-none d-md-block">

                                </div>
                            </div>
                            <div className="carousel-item" data-bs-interval="3000">
                            <picture>
                                    <source media="(max-width: 540px)" srcSet="/images/banner/m_home_31.webp" width="1647" height="720" className='m_home-31'/>
                                    <source media="(min-width: 541px)" srcSet="/images/banner/home_3.webp" />
                                    <img width="1920" height="720"  src="/images/banner/home_3.webp"  className="d-block  carousel-image " alt="..." />
                            </picture>
                                <div className="carousel-caption d-none d-md-block">

                                </div>
                            </div>
                            <div className="carousel-item" data-bs-interval="3000">
                            <picture>
                                    <source media="(max-width: 540px)" srcSet="/images/banner/m_home_41.webp" width="1645" height="720" className='m_home-21'/>
                                    <source media="(min-width: 541px)" srcSet="/images/banner/home_4.webp" />
                                    <img width="1920" height="720"  src="/images/banner/home_4.webp"  className="d-block  carousel-image " alt="..." />
                            </picture>
                                <div className="carousel-caption d-none d-md-block">

                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </section>
                {/* hero section end */}
                <section className="services-area section-padding-100-0 d-flex flex-column justify-content-center align-items-center">
                    <div className="container">
                        <div className="row justify-content-center ">
                            <div className="col-12">
                                <div className="section-heading text-center mb-100">
                                    <div className=" line"></div>
                                    <p>For a high definition lifestyle. Embrace Kefi.</p>
                                    <h2>Our Products</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                <div className="col-12 col-md-4">
                    <div className="single-service-area d-flex mb-100 wow fadeInUp" data-wow-delay="100ms">
                        <div className="text text-center">
                            <div className="single-features-area mb-100 text-center"> <img effect='blur' width="100%" height="auto" src="/images/banner/device.avif" alt=""/>
                                <h5>Kefi-C13 WiFi Router</h5>
                                <p >Supports IEEE802.11b/g/n global standards, Uses Multiple
                                    Input Multiple Output technology, High Bandwidth upto 1300 MBPS, Highly Secured With
                                    WEP, WPA and WPA2, Provides Access Control (IP & MAC based filters) & Parental
                                    Control</p><br/>
                                <a href="https://amzn.eu/d/gNfmB8z"   target="_blank"
                        rel="noopener noreferrer" className="btn kefi-btn mt-50">Buy Now</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="single-service-area d-flex mb-100 wow fadeInUp" data-wow-delay="100ms">
                        <div className="text text-center">
                            <div className="single-features-area mb-100 text-center"> <img effect='blur' width="100%" height="auto" src="/images/banner/device_3.avif" alt=""/>
                                <h5>Kefi-C13 WiFi Mesh System</h5>
                                <p >A Pack of Three routers, High Bandwidth expandable to
                                    1300Mbps, Provides one WAN and one LAN Port, Supports 2.4GHz and 5GHz interface
                                    and all other features including in our C13, C12 and E12 Model.</p>
                                <br/> <a href="https://amzn.eu/d/ai41XNc"   target="_blank"
                        rel="noopener noreferrer" className="btn kefi-btn mt-50">Buy Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                    </div>
                </section>
                {/* product section end */}
                <section className="services-area section-padding-100-0 bg-service">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="section-heading text-center mb-100 ">
                                    <div className="line"></div>
                                    <p>We are working very hard to provide our customers Best-In-Class Wifi Services</p>
                                    <h2>Our services</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-4">
                                <div className="single-service-area d-flex mb-100 ">
                                    <div className="text text-center">
                                        <div className="icon" > <i
                                            className="fa fa-mobile"></i></div>
                                        <h5 >Kefi Wifi App</h5>
                                        <p >Recipes at the snap of your fingers - Manage | Pause WiFi |
                                            Speed
                                            Test | Bandwidth Monitoring | Parental Control</p><br /> <Link to="/kefiapp"
                                                className="btn kefi-btn mt-50">Explore Kefi App</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="single-service-area d-flex mb-100 ">
                                    <div className="text text-center">
                                        <div className="icon" > <i
                                            className="fa fa-laptop"></i></div>
                                        <h5 >Kefi Wifi Admin Panel</h5>
                                        <p >On the go full control of Network - Kefi now provides freedom
                                            to control the network even through dashboard remotely.</p><br /> <Link
                                                to="/kefiadmin" className="btn kefi-btn mt-50">Explore Kefi Dashboard</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="single-service-area d-flex mb-100 ">
                                    <div className="text text-center">
                                        <div className="icon"> <i
                                            className="icon-fa fa fa-ellipsis-h" aria-hidden="true"></i></div>
                                        <h5 >Others</h5>
                                        <p >Restricted WiFi Access | Hotspot Billing Software & Many More
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* services section end */}
                <section className="about-area section-padding-100-0 ">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6">
                                <div className="about-content mb-100">
                                    <div className="section-heading">
                                        <div className="line"></div>
                                        <p>Introducing</p>
                                        <h2>The Kefi App</h2>
                                    </div>
                                    <h6 className="mb-4">Easy & Fast WiFi Setup is now at your Fingertip</h6>
                                    <p className="mb-0">The Kefi app makes it very easier to setup wifi network at your place. You just
                                        need to plug your router and login to our app and the app will help you afterwards. The
                                        whole process will hardly take 5 to 10 minutes. You can easily monitor your home network
                                        from anywhere remotely. protecting your childrens from evils of the internet is now very
                                        easy using our parental control feature. The list is too long, we provide Internet speed
                                        test interface to find your network provider internet speed, now your network is secure
                                        using kefi antivirus too.</p> <Link to="/kefiapp" className="btn kefi-btn mt-50">Learn More About Kefi</Link>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="about-thumbnail mb-100"> <img effect='blur' src="/images/banner/banner_30.webp" alt="banner" width="100%" height="auto"
                                    /></div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="cta-area d-flex flex-wrap ">
                    <div className="cta-thumbnail bg-img designed-img">
                    </div>
                    <div className="cta-content">
                        <div className="section-heading black">
                            <div className="line"></div>
                            <p>Designed Asthetically</p>
                            <h2>Designed & Developed To Fit In Any Environment</h2>
                        </div>
                        <h6>It is designed and developed in order to fit in any environment. It is the root of your network that
                            manages its mesh routers wirelessly. These routers uses highly optimized mesh networking topologies so
                            as
                            to provide no packet loss, high speed network. These are high range routers of wide network range. It
                            can cover all places of your home like hallways, bathrooms and terrace too.</h6>
                        <div className="d-flex flex-wrap mt-50">
                            <div className="single-skils-area mb-70 mr-5">

                                <div className="col-md-3 col-sm-6">
                                    <div className="progress blue">
                                        <span className="progress-left">
                                            <span className="progress-bar"></span>
                                        </span>
                                        <span className="progress-right">
                                            <span className="progress-bar"></span>
                                        </span>
                                        <div className="progress-value">90%</div>
                                    </div>
                                </div>
                                <p>Energy</p>
                            </div>
                            <div className="single-skils-area mb-70 mx-3">
                                <div className="col-md-3 col-sm-6">
                                    <div className="progress blue yellow">
                                        <span className="progress-left">
                                            <span className="progress-bar"></span>
                                        </span>
                                        <span className="progress-right">
                                            <span className="progress-bar"></span>
                                        </span>
                                        <div className="progress-value">75%</div>
                                    </div>
                                </div>
                                <p>power</p>
                            </div>
                            <div className="single-skils-area mb-70">
                                <div className="col-md-3 col-sm-6">
                                    <div className="progress blue green">
                                        <span className="progress-left">
                                            <span className="progress-bar"></span>
                                        </span>
                                        <span className="progress-right">
                                            <span className="progress-bar"></span>
                                        </span>
                                        <div className="progress-value">97%</div>
                                    </div>
                                </div>
                                <p>resource</p>
                            </div>
                        </div> <Link to="/kefiapp" className="btn kefi-btn box-shadow btn-2">Read More About Kefi </Link>
                    </div>
                </section>
                <section className="cta-area d-flex flex-wrap grid-destroy ">
                    <div className="cta-content">
                        <div className="section-heading black">
                            <div className="line"></div>
                            <p>Getting Started</p>
                            <h2>Quick and Easy to Setup</h2>
                        </div>
                        <h6>
                            Kefi is designed to deliver its promise of seamless experience right out of the box. If you are
                            someone who shivers at the thought of setting up gadgets. Don’t fret. Kefi is here. We promise in
                            under 10 minutes, you will have a fast and reliable WiFi up and running. Start by installing the app
                            and plug your Kefi to the modem. Sign in to our app, scan the QR code given right at the back of device
                            and you are done.
                            Kefi app knows your home devices and uses Artificial Intelligence to recognize strange devices connected
                            to your network and informs you about it.
                        </h6>
                        <div className="d-flex flex-wrap mt-50">
                            <div className="single-skils-area mb-70 mr-5">
                            <div className="progress blue green">
                                        <span className="progress-left">
                                            <span className="progress-bar"></span>
                                        </span>
                                        <span className="progress-right">
                                            <span className="progress-bar"></span>
                                        </span>
                                        <div className="progress-value">5 min</div>
                                    </div>
                                <p>Root Setup</p>
                            </div>
                            <div className="single-skils-area mb-70 mr-5 mx-3">
                            <div className="progress blue">
                                        <span className="progress-left">
                                            <span className="progress-bar"></span>
                                        </span>
                                        <span className="progress-right">
                                            <span className="progress-bar"></span>
                                        </span>
                                        <div className="progress-value">2 min</div>
                                    </div>
                                <p>Mesh Setup</p>
                            </div>
                        </div> <Link to="/kefiapp" className="btn kefi-btn box-shadow btn-2">Explore The App</Link>
                    </div>
                    <div className="cta-thumbnail bg-img explore-img"
                       >
                    </div>
                </section>
                {/* app */}
               
                {/* contact-button  */}
                <section className="cta-2-area ">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="cta-content d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="cta-text">
                                        <h4>Are you confused about which kefi to buy? Don't worry, Contact Us.</h4>
                                    </div>
                                    <div className="cta-btn"> <Link to="/contact" className="btn kefi-btn box-shadow">Contact Us</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="services-area section-padding-100-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="section-heading text-center mb-100 ">
                                    <div className="line"></div>
                                    <h2>Smartest Than Ever</h2>
                                    <p>Kefi uses highly optimized algorithms and artificial intelligence in order to provide you the
                                        strongest network. It's underlying architecture changes according to any change in network.
                                        It protects your network from unknown devices using WPA, WPA2, WEP algorithms and informs
                                        you through notification whenever new client gets connected. It manages the bandwidth of
                                        your devices according to their requirements.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="align-items-end justify-content-center">
                                <div className="single-blog-area mb-70">
                                    <div className="blog-thumbnail"> <img effect='blur' src="/images/banner/banner_20.webp" alt="banner" width="100%" height="auto" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <br />
                <section className="services-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="section-heading text-center mb-100">
                                    <div className="line"></div>
                                    <h2>Wide range coverage, Touch every nook & corner of your home</h2>
                                    <p>Unlike old age routers that uses 802.11a, Kefi router uses 2.4GHz and 5GHz band that makes it
                                        easy for it to
                                        cover and provide stable network in every corner oy your home.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="align-items-end justify-content-center" >
                                <div className="single-blog-area mb-70">
                                    <div className="blog-thumbnail text-center"> <img effect='blur' src="/images/banner/banner_4.webp"
                                        alt="banner" width="100%" height="auto" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="team-area bg-gray section-padding-100-0">
                    <div className="container ">
                        <div className="row">
                            <div className="col-12">
                                <div className="section-heading text-center mb-100">
                                    <div className="line"></div>
                                    <p>Kefi listens to your voice</p>
                                    <h2>Take Full Control Of Your Network Using Our Integrations</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-end justify-content-center">
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="single-team-member-area mb-100">
                                    <div className="team-thumb"> <img effect='blur' src="/images/banner/alexa.jpg" alt="alexa" width="100%" height="auto" /></div>
                                    <div className="team-info">
                                        <h5>Alexa</h5>
                                        <h6>It allows you to control your router using voice commands. You can turn on/off night
                                            mode, change your router to access point, switch on/off your guest network using it.
                                            Like you may ask "Alexa, Ask kefi to perform the internet speed test"</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="single-team-member-area mb-100">
                                    <div className="team-thumb"> <img effect='blur' src="/images/banner/googlehome.jpg" alt="googlehome" width="100%" height="auto" /></div>
                                    <div className="team-info">
                                        <h5>Google Assistant</h5>
                                        <h6>Other thank alexa integration, kefi also provides google assistant to make your router
                                            listen to you and follow your commands.For example, You just need to ask, "Hey Google,
                                            Ask Kefi to turn on night mode." </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            
        </div>
    )
}
