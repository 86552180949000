import React from 'react'
import { HashLink as Link } from "react-router-hash-link";
 
export default function Contact() {
  return (
    <>
          <section className="breadcrumb-area bg-img bg-overlay contact-paralax mrg-top-all">
        <div className="container h-100">
            <div className="row h-100 align-items-center">
                <div className="col-12">
                    <div className="breadcrumb-content">
                        <h2>Contact</h2>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="contact-area section-padding-100-0" >
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-4">
                    <div className="single-contact-area mb-100">
                        <div className="contact--area contact-page">
                            <div className="contact-content">
                                <h5>Get in touch</h5>
                                <div className="single-contact-content d-flex align-items-center">
                                    <div className="icon" > <i className="fa fa-comment"
                                            aria-hidden="true" ></i></div>
                                    <div className="text">
                                        <p>support@kefikonnect.com</p> <span>we reply in 24 hrs</span>
                                    </div>
                                </div>
                                <div className="single-contact-content d-flex align-items-center">
                                    <div className="icon" > <i className="fa fa-black-tie"
                                            aria-hidden="true"></i></div>
                                    <div className="text">
                                        <p>admin@kefikonnect.com</p> <span>we reply in 24 hrs</span>
                                    </div>
                                </div>
                                <div className="single-contact-content d-flex align-items-center">
                                    <div className="icon" > <i className="fa fa-graduation-cap"
                                            aria-hidden="true"></i></div>
                                    <div className="text">
                                        <p>careers@kefikonnect.com</p> <span>we reply in 24 hrs</span>
                                    </div>
                                </div>
                                <h5>Address</h5>
                                <div className="single-contact-content d-flex align-items-center">
                                    <div className="text">
                                       <p>US Office: </p> <span>3279 primrose Dr. Rochester hills MI 48307 USA
                                        </span>
                                        <br/><br/>
                                        <p>India Plant: </p><span>358, Export Promotion Industrial Park (EPIP)
                                            HSIIDC Industrial Area, Phase – III, Kundli, Haryana - 131028. INDIA</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-8">
                    <div className="map-area">
                        <div className="contact---area">
                            <div className="contact-form-area contact-page">
                                <h4 className="mb-50">Or<br/>Send us a message</h4>
                                <form method="POST" action="/contact-kefi">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group"> <input required type="text" name="name"
                                                    maxLength="50" className="form-control" id="name"
                                                    placeholder="Your Name"/></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group"> <input required type="text" maxLength="10"
                                                    name="phone" className="form-control" id="phone"
                                                    placeholder="Your Mobile Number"/></div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group"> <input required type="email" name="email"
                                                    maxLength="255" className="form-control" id="email"
                                                    placeholder="Your Email"/></div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group"><textarea required name="details" maxLength="500"
                                                    className="form-control" id="details" cols="30" rows="10"
                                                    placeholder="Your Message"></textarea></div>
                                        </div>
                                        <div className="col-12"> <button className="btn kefi-btn mt-30"
                                                type="submit">Send</button></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> 
    </>
  )
}
