import React from 'react'
// import {  LazyLoadImage } from 'react-lazy-load-image-component';
// import 'react-lazy-load-image-component/src/effects/blur.css';
 

import { HashLink as Link } from "react-router-hash-link";
export default function Working() {
  return (
    <>
            <section className="breadcrumb-area bg-img bg-overlay jarallax working-paralax mrg-top-all">
        <div className="container h-100">
            <div className="row h-100 align-items-center">
                <div className="col-12">
                    <div className="breadcrumb-content">
                        <h2>How It Works</h2>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">How It Works</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="about-area section-padding-100-0 ">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-12 col-md-6">
                    <div className="about-content mb-100">
                        <div className="section-heading">
                            <div className="line"></div>
                            <p>IT'S NOT EASY AS IT LOOKS</p>
                            <h2>How It Works</h2>
                        </div>
                        <h6 className="mb-4">The brain of your network.</h6>
                        <p className="mb-0">Kefi provides you a fast and stable WiFi network by using Mesh technology that
                            connects all routers together. Each router communicate with each
                            other and share internet all together, only root device connect to internet and share it
                            with
                            other routers. It's range is much greater than any other wifi network with enhanced
                            performance
                            than your normal WiFi network.
                            <br/>Kefi extends your Wi-Fi signal by mesh devices and provide full home coverage.
                            Kefi is preferred over other Wi-Fi range extenders because all kefi routers have same
                            network
                            ssid and password and it maintains your stable connection even if you roam anywhere in the
                            house,
                            and do not need reconnection to other mesh device. On the other hand, Range extenders
                            provide you different
                            Wi-Fi network that requires reconnection for Wi-Fi access. So in this way you can control
                            your network easily.<br/></p>
                    
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="about-thumbnail mb-100"> <img effect='blur' width="100%" height="auto" src="/images/banner/brain.webp" alt="brain"
                            /></div>
                </div>
            </div>
        </div>
    </section>
    <section className="cta-area d-flex flex-wrap">
        <div className="cta-thumbnail complex-img"
            >
        </div>
        <div className="cta-content">
            <div className="section-heading white">
                <div className="line"></div>
                <p>LET'S BEGIN</p>
                <h2>Complexity simplified</h2>
            </div>
            <h6>Kefi is developed and designed while taking care of the user's ease of use. There is no issue even if
                you are not very good at networking. We have taken care of it right out of the box. You will find QR
                Code
                right at back of device, just scan it and kefi app will locate your device and will get connect to it to
                complete the setup. The App interface is so easy to use that you can control your network very easily.
            </h6>
        </div>
    </section>
    <section className="cta-area d-flex flex-wrap grid-destroy">
        <div className="cta-content">
            <div className="section-heading white">
                <div className="line"></div>
                <p>IT'S NOT JUST AN APP, IT'S YOUR COMPANION</p>
                <h2>App will guide you throughout the process</h2>
            </div>
            <h6>The underlying mesh architecture is intelligent enough to detect any device failure in network. It then
                automatically find the best path to reach the internet and continue to provide internet services without
                any break.The app will take you through the rest of the process, you can not only create the network but
                also extend your network using adding device option given in app.</h6>
                <div className="d-flex flex-wrap mt-50">
                            <div className="single-skils-area mb-70 mr-5">
                            <div className="progress blue green">
                                        <span className="progress-left">
                                            <span className="progress-bar"></span>
                                        </span>
                                        <span className="progress-right">
                                            <span className="progress-bar"></span>
                                        </span>
                                        <div className="progress-value">5 min</div>
                                    </div>
                                <p>Root Setup</p>
                            </div>
                            <div className="single-skils-area mb-70 mr-5 mx-3">
                            <div className="progress blue">
                                        <span className="progress-left">
                                            <span className="progress-bar"></span>
                                        </span>
                                        <span className="progress-right">
                                            <span className="progress-bar"></span>
                                        </span>
                                        <div className="progress-value">2 min</div>
                                    </div>
                                <p>Mesh Setup</p>
                            </div>
                        </div> <Link to="/kefiapp" className="btn kefi-btn box-shadow btn-2">Explore The App</Link>
        </div>
        <div className="cta-thumbnail bg-img explore-img"
           ></div>
    </section><br/><br/><br/><br/>
    <section className="cta-area d-flex flex-wrap ">
        <div className="cta-thumbnail position-img">
        </div>
        <div className="cta-content bg-service">
            <div className="section-heading  ">
                <div className="line"></div>
                <p>FIND A SUITABLE PLACE FOR YOUR KEFI</p>
                <h2>Position your kefi and you are done</h2>
            </div>
            <h6>In the end, you will be asked to place your kefi unit. Just choose from the given option or give a
                custom name. You will also be asked if you want to add another device, if yes then follow the same
                process or if no then you are done.</h6>
        </div>
    </section>
    <section className="team-area section-padding-100-0">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="section-heading text-center mb-100">
                        <div className="line"></div>
                        <p>LED Indicators</p>
                        <h2>Getting smarter all the time</h2>
                        <p>Kefi Wifi uses LED indicators to notify user the current process running behind the scene.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row ">
                <div className="col-12 col-md-6">
                    <div className="single-team-member-area mb-100">
                        <div className="led-container"> <img effect='blur' width="100%" height="auto" src="/images/banner/device-led.jpg" alt="led"/>
                            <div className="centered-led responsive-led led-solid-orange"></div>
                        </div>
                        <div className="team-info">
                            <h5>Solid Orange</h5>
                            <h6>Solid orange light indicates that your kefi device is starting up</h6>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="single-team-member-area mb-100">
                        <div className="led-container"> <img effect='blur' width="100%" height="auto" src="/images/banner/device-led.jpg" alt="led "/>
                            <div className="centered-led responsive-led led-solid-blue-blink"></div>
                        </div>
                        <div className="team-info">
                            <h5>Pulsing Blue</h5>
                            <h6>Pulsing blue indicates that your kefi device is ready for setup</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="single-team-member-area mb-100">
                        <div className="led-container"> <img effect='blur' src="/images/banner/device-led.jpg" alt="led" width="100%" height="auto"/>
                            <div className="centered-led responsive-led-mini led-solid-green-blink"></div>
                        </div>
                        <div className="team-info">
                            <h5>Pulsing Green</h5>
                            <h6>Pulsing green light indicates that your kefi device is setting up</h6>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-4">
                    <div className="single-team-member-area mb-100">
                        <div className="led-container"> <img effect='blur' src="/images/banner/device-led.jpg" alt="led" width="100%" height="auto"/>
                            <div className="centered-led responsive-led-mini led-solid-green"></div>
                        </div>
                        <div className="team-info">
                            <h5>Solid Green</h5>
                            <h6>Solid green light indicates that everything is fine</h6>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="single-team-member-area mb-100">
                        <div className="led-container"> <img effect='blur' src="/images/banner/device-led.jpg" alt="led" width="100%" height="auto"/>
                            <div className="centered-led responsive-led-mini led-solid-red"></div>
                        </div>
                        <div className="team-info">
                            <h5>Solid Red</h5>
                            <h6>Solid red light indicates that internet is not available</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row ">
                <div className="col-12 col-md-4">
                    <div className="single-team-member-area mb-100">
                        <div className="led-container"> <img effect='blur' src="/images/banner/device-led.jpg" alt="led" width="100%" height="auto"/>
                            <div className="centered-led responsive-led-mini led-solid-yellow-blink"></div>
                        </div>
                        <div className="team-info">
                            <h5>Pulsing Yellow</h5>
                            <h6>Pulsing yellow light indicates that your mesh device is too near to your root device
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="single-team-member-area mb-100">
                        <div className="led-container"> <img effect='blur' src="/images/banner/device-led.jpg" alt="led" width="100%" height="auto"/>
                            <div className="centered-led responsive-led-mini led-solid-red-blink"></div>
                        </div>
                        <div className="team-info">
                            <h5>Pulsing Red</h5>
                            <h6>Pulsing red light indicates that your mesh device is too far from your root device</h6>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="single-team-member-area mb-100">
                        <div className="led-container"> <img effect='blur' src="/images/banner/device-led.jpg" alt="led" width="100%" height="auto"/>
                            <div className="centered-led responsive-led-mini led-solid-purple-blink"></div>
                        </div>
                        <div className="team-info">
                            <h5>Pulsing Purple</h5>
                            <h6>Pulsing purple light indicates that your kefi device is updating</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  
    </>
  )
}
