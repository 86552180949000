import './App.css';
import About from './component/About';
import Footer from './component/Footer';
import Navbar from './component/Navbar';
import Career from './component/Career';
import Kefiapp from './component/Kefiapp';
import Kefiadmin from './component/Kefiadmin';
import Working from './component/Working';
import Contact from './component/Contact';
import ScrollToTop from "./component/ScrollTop.js";
import { Routes, Route, HashRouter } from "react-router-dom";
import React from 'react'
import Home from './component/Home';
import Nopage from './component/Nopage';

function App() {
  return (
    <div id="main-container">
    <HashRouter>
          <ScrollToTop/>
    <>
        <Navbar/>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/index.html" element={<Home/>}/>
          <Route exact path="/about" element={<About/>}/>
          <Route exact path="/career" element={<Career/>}/>
          <Route exact path="/kefiapp" element={<Kefiapp/>}/>
          <Route exact path="/kefiadmin" element={<Kefiadmin/>}/>
          <Route exact path="/working" element={<Working/>}/>
          <Route exact path="/contact" element={<Contact/>}/>
          <Route path='*'  element={<Nopage/>}/>
        </Routes>
      <Footer/>
    </>
    </HashRouter>
    </div>
  );
}
export default App;
