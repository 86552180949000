import React, { useEffect, useState } from 'react'
import { HashLink as Link } from "react-router-hash-link";
// import 'react-lazy-load-image-component/src/effects/blur.css';
// import {  LazyLoadImage } from 'react-lazy-load-image-component';
 
export default function Kefiapp() {
  const[eleId,setEleId]=useState('manage-id');
  var manage = "View the status of your WiFi, a list of the connected devices, and configure all the features of your router from anywhere anytime through mobile app and cloud based dashboard. Multiple devices can connect to the Kefi system at the same time. The App allows you to monitor their data usage in real time";
  var manageImg="/images/banner/manage.webp";
  var qos = "Now you can prioritizes your network traffic anytime. For example, Gaming, Streaming, Surfing, Chatting, Standard and Custom. Thus customize your network speed according to your needs.";
  var qosImg="/images/banner/qos.webp";
  var speed = "Track & Find out your internet speed as provided by your internet service provider.";
  var speedImg="/images/banner/speedtest.webp";
  var parental = "We understand that you need to protect your family from the perils of internet. Kefi allows you to easily manage the time spent online for all the connected devices. You can schedule a time-out for specific devices at certain times.";
  var parentalImg="/images/banner/parental.webp";
  var blacklist = "For us, your protection is of foremost priority. Kefi helps you to blacklist any device that you want not to connect.";
  var blacklistImg="/images/banner/blacklist.webp";
  var manager = "The email through which you sign up is called the 'root or owner' account. The owner manages the network. In any case if you need another person in your family to take care of it, you can create 'manager' account.";
  var managerImg="/images/banner/manager.webp";
  const [active, setActive] = useState("manage-id");
  useEffect(() => {
    // console.log(eleId)
  }, [eleId]);
  const clickManage = (event) => {
    setActive(event.target.id);
    // event.currentTarget.classList.toggle('bg-salmon');
    // event.currentTarget.classList.add('bg-salmon','my-class-2','my-class-3',);
    // event.currentTarget.classList.remove('bg-salmon); slider_background
  
       if(event.target.id==='speed-id'){
         document.getElementById("p-tab").innerHTML = speed;
         document.getElementById("slider_background").setAttribute("src",speedImg);
        
        }
        else if(event.target.id==='manage-id'){

          document.getElementById("p-tab").innerHTML = manage;
          document.getElementById("slider_background").setAttribute("src",manageImg);
         
        }
        else if(event.target.id==='qos-id'){

          document.getElementById("p-tab").innerHTML = qos;
          document.getElementById("slider_background").setAttribute("src",qosImg);
        }
        else if(event.target.id==='blacklist-id'){

          document.getElementById("p-tab").innerHTML = blacklist;
          document.getElementById("slider_background").setAttribute("src",blacklistImg);
        }
        else if(event.target.id==='manager-id'){

          document.getElementById("p-tab").innerHTML = manager;
          document.getElementById("slider_background").setAttribute("src",managerImg);
        }
        else if(event.target.id==='parental-id'){
          document.getElementById("p-tab").innerHTML = parental;
          document.getElementById("slider_background").setAttribute("src",parentalImg);
        }
      setEleId(event.target.id);

  
    }
  return (
    <>
      <section className="breadcrumb-area bg-img bg-overlay jarallax app-paralax mrg-top-all">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12">
              <div className="breadcrumb-content">
                <h2>Apps & Services</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item" >
                      Apps & Services</li>
                    <li className="breadcrumb-item active" aria-current="page">Kefi App</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-area section-padding-100-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-9 ">
              <div className="about-content mb-100">
                <div className="section-heading">
                  <div className="line"></div>
                  <p>Now control is in your hands</p>
                  <h2>Introducing our Kefi App</h2>
                </div>
                <h6 className="mb-4">WiFi control on the tip of your fingers.</h6>
                <div className="kefi-tabs-content">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                      <div className="scf-icon mr-15 tab-icon" > <i id="manage_icon"
                        className="fa fa-home app-tab-icon-manage app-tab-icon-active" 
                        onClick={clickManage}></i></div> <button onClick={clickManage}
                          className={active==="manage-id"?"nav-link active":"nav-link"} id="manage-id" data-toggle="tab"  role="tab"
                          aria-controls="tab1" aria-selected="true">Manage</button>
                    </li>
                    <li className="nav-item">
                      <div className="scf-icon mr-15 tab-icon"> <i id="blacklist_icon"
                        className="fa fa-user-times app-tab-icon-blacklist"
                        ></i></div> <button onClick={clickManage}
                        className={active==="blacklist-id"?"nav-link active":"nav-link"} id="blacklist-id" data-toggle="tab"  role="tab"
                          aria-controls="tab5" aria-selected="false">Blacklist</button>
                    </li>
                    <li className="nav-item">
                      <div className="scf-icon mr-15 tab-icon"> <i id="managers_icon"
                        className="fa fa-black-tie app-tab-icon-managers" ></i>
                      </div> <button onClick={clickManage} className={active==="manager-id"?"nav-link active":"nav-link"}  id="manager-id" data-toggle="tab"
                         role="tab" aria-controls="tab6" aria-selected="false">Manager</button>
                    </li>
                    <li className="nav-item">
                      <div className="scf-icon mr-15 tab-icon"> <i id="parental_icon"
                        className="fa fa-child app-tab-icon-parental" ></i>
                      </div> <button onClick={clickManage} className={active==="parental-id"?"nav-link active":"nav-link"}  id="parental-id" data-toggle="tab"
                        role="tab" aria-controls="tab2" aria-selected="false">Parental</button>
                    </li>
                    <li className="nav-item">
                      <div className="scf-icon mr-15 tab-icon"> <i id="qos_icon"
                        className="fa fa-bar-chart app-tab-icon-qos" ></i></div> <button
                        onClick={clickManage} className={active==="qos-id"?"nav-link active":"nav-link"}  id="qos-id" data-toggle="tab" 
                          role="tab" aria-controls="tab4" aria-selected="false">QoS</button>
                    </li>
                    <li className="nav-item">
                      <div className="scf-icon mr-15 tab-icon"> <i id="speedtest_icon"
                        className="fa fa-tachometer app-tab-icon-speedtest"
                        ></i></div> <button onClick={clickManage}
                        className={active==="speed-id"?"nav-link active":"nav-link"}  id="speed-id" data-toggle="tab"  role="tab"
                          aria-controls="tab3" aria-selected="false">Speed Test</button>
                    </li>
                  </ul>
                  <div className="col-12 col-md-9 tab-content mb-100" id="myTabContent">
                    <div className="tab-pane fade show active" id="tab1" role="tabpanel"
                      aria-labelledby="tab--1">
                      <div className="kefi-tab-content">
                        <div className="kefi-tab-text">
                          <p id="p-tab">View the status of your WiFi, a list of the
                                                connected devices, and configure all the features of
                                                your router from anywhere anytime through mobile app and cloud based
                                                dashboard.
                                                Multiple devices can connect to the Kefi system at the same time. The
                                                App allows you to
                                                monitor their data usage in real time
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* //eslint-disable-next-line */}
              <div className="row align-items-center showcase" >
                <a href='https://play.google.com/store/apps/details?id=com.kefi&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                  target="_blank" rel="noopener noreferrer" aria-label="Navigation route">
                  <img effect='blur'  className="android-badge" alt='Get it on Google Play' width="100%" height="auto" src='/images/banner/google-play-badge.jpg' /></a>
                <a href="https://apps.apple.com/us/app/kefi/id1473117839?mt=8" target="_blank" rel="noopener noreferrer" aria-label="Navigation route">
                  <img effect='blur' width="100%" height="auto"  src="/images/banner/appstore.webp" className='apple-badge' alt="" />
                </a>
              </div>
              {/* <div className="row align-items-center showcase" >
                <a href='https://play.google.com/store/apps/details?id=com.kefi&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                  target="_blank" rel="noopener noreferrer">
                  <imgeffect='blur'  className="android-badge" alt='Get it on Google Play' width="100%" height="auto" src='/images/banner/google-play-badge.jpg' /></a>
                <a href="https://apps.apple.com/us/app/kefi/id1473117839?mt=8" target="_blank" rel="noopener noreferrer">
                  <imgeffect='blur' width="100%" height="auto"  src="https://linkmaker.itunes.apple.com/en-gb/badge-lrg.svg?releaseDate=2019-07-19&kind=iossoftware&bubble=ios_apps" className='apple-badge' alt="" />
                </a>
              </div> */}
            </div>
            <div className="col-12 col-md-3 ">
              <div className="about-thumbnail mb-100">
                <div className="phone">
                  <div className="bezel">
                    <div className="buttons">
                      <div className="camera"></div>
                      <div className="sensor"></div>
                    </div>
                    <div id="show">
                      <div className="screen locked"> <img effect='blur'  id="slider_background" className="wallpaper"
                        src="/images/banner/manage.webp" alt="background" width="100%" height="auto"/></div>
                      <div className="locked home-btn"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="team-area section-padding-100-0 ">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-heading text-center mb-100">
                <div className="line"></div>
                <p>It's not an end</p>
                <h2>Manage Multiple Networks</h2>
              </div>
            </div>
          </div>
          <div className="row index">
            <div className="col-12 col-md-4">
              <div className="single-team-member-area mb-100">
                <div className="phone">
                  <div className="bezel">
                    <div className="buttons">
                      <div className="camera"></div>
                      <div className="sensor"></div>
                    </div>
                    <div id="show">
                      <div className="screen locked"> <img effect='blur'  className="wallpaper"
                        src="/images/banner/network.webp" alt="background" width="100%" height="auto"/></div>
                      <div className="locked home-btn"></div>
                    </div>
                  </div>
                </div>
                <div className="team-info mt-5">
                  <h5>Multiple Network Management</h5>
                  <h6>Select any network at any time and continue surfing.
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="single-team-member-area mb-100">
                <div className="phone">
                  <div className="bezel">
                    <div className="buttons">
                      <div className="camera"></div>
                      <div className="sensor"></div>
                    </div>
                    <div id="show">
                      <div className="screen locked"> <img effect='blur'  className="wallpaper"
                        src="/images/banner/notification.webp" alt="background"  width="100%" height="auto"/></div>
                      <div className="locked home-btn"></div>
                    </div>
                  </div>
                </div>
                <div className="team-info mt-5">
                  <h5>Notifications</h5>
                  <h6>Get notified whenever new client connects or your kefi unit goes offline.</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="team-area section-padding-100-0 ">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-heading text-center mb-100">
                <div className="line"></div>
                <p>There is lot more</p>
                <h2>A Wifi that take cares of your family</h2>
              </div>
            </div>
          </div>
          <div className="row index">
            <div className="col-12 col-md-4">
              <div className="single-team-member-area mb-100">
                <div className="phone">
                  <div className="bezel">
                    <div className="buttons">
                      <div className="camera"></div>
                      <div className="sensor"></div>
                    </div>
                    <div id="show">
                      <div className="screen locked"> <img effect='blur'  className="wallpaper"
                        src="/images/banner/createparental.webp" alt="background" width="100%" height="auto"/></div>
                      <div className="locked home-btn"></div>
                    </div>
                  </div>
                </div>
                <div className="team-info mt-5">
                  <h5>Parental Controls</h5>
                  <h6>With kefi, you can schedule your children's internet usage duration. You can also track
                    your children's activities and data usage.</h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="single-team-member-area mb-100">
                <div className="phone">
                  <div className="bezel">
                    <div className="buttons">
                      <div className="camera"></div>
                      <div className="sensor"></div>
                    </div>
                    <div id="show">
                      <div className="screen locked"> <img effect='blur'  className="wallpaper"
                        src="/images/banner/parental.webp" alt="background" width="100%" height="auto" /></div>
                      <div className="locked home-btn"></div>
                    </div>
                  </div>
                </div>
                <div className="team-info mt-5">
                  <h5>Members Control</h5>
                  <h6>Just Create a profile for any member and schedule their timings or week days on which
                    they can use the internet and keep track of their activities.</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-heading text-center mb-100 ">
                <div className="line"></div>
                <p>Quickest & Easiest Setup Ever</p>
                <h2>Complete WiFi Setup in less than 10 minutes</h2>
                <p>Just sign in our app, plug the device, scan qr code and place your kefi and that's it. You
                  are done.</p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="align-items-end justify-content-center" >
              <div className="single-blog-area mb-70">
                <div className="blog-thumbnail text-center"> <a href="/"><img effect='blur' width="100%" height="auto" src="/images/banner/position.webp"
                  alt="link" /></a></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
  
    </>
  )
}
