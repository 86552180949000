import React from 'react'
import { HashLink as Link } from "react-router-hash-link";
// import 'react-lazy-load-image-component/src/effects/blur.css';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
 
export default function Career() {
  return (
    <>
         <section className="breadcrumb-area bg-img bg-overlay jarallax career-paralax mrg-top-all">
        <div className="container h-100">
            <div className="row h-100 align-items-center">
                <div className="col-12">
                    <div className="breadcrumb-content">
                        <h2>Career</h2>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Career</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="about-area section-padding-100-0">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-12 col-md-6">
                    <div className="about-content mb-100">
                        <div className="section-heading">
                            <div className="line"></div>
                            <p>Kefi</p>
                            <h2>Why you should join us?</h2>
                        </div>
                        <ul className="joinli diamond ">
                            <li>Here at Kefi, we let our employees grow with providing enough scope for development.
                            </li>
                            <li>Here at Kefi, You will find an environment that is suitable for any innovation.</li>
                            <li>Here at Kefi, We take recognize the expertise of our employees at very early step.</li>
                            <li>Here at Kefi, We encourage our employees by providing them regular perks and facilities
                                to enhance their skills.</li>
                            <li>Here at Kefi, We provide In-House trainings so that the newly joined employee can
                                understand the working culture of our company.
                            </li>
                            <li>Here at Kefi, We believe in healthy competition, so we encourage our employees by
                                acknowledging their work by awards.</li>
                            <li>Here at Kefi, You will enjoy the challenges provided, and this will help you in growing
                                your skills.</li>
                        </ul>
                        <p className="mb-0"> If Interested in any of the domains given
                            below then, write us at <a href="/" >careers@kefikonnect.com</a>
                            corresponding to your choice of domain or fill the application form.</p>
                    </div>
                </div>
                <div className="col-12 col-md-6 ">
                    <div className="about-thumbnail mb-100"> <img  effect='blur' width="100%" height="auto" src="/images/banner/banner_28.webp" alt=""
                            /></div>
                </div>
            </div>
        </div>
    </section>
    <section className="about-area ">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-12">
                    <div className="elements-title mb-30">
                        <div className="line"></div>
                        <h2>Our Domains</h2>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="single-service-area d-flex mb-100">
                                <div className="icon"> <i className="fa fa-code" aria-hidden="true"></i></div>
                                <div className="text">
                                    <h5>Software</h5>
                                    <p >A talented software development professional with a
                                        deep technical background and experience building excellent user focused
                                        products and who will drive forward innovation to deliver cutting edge
                                        application solutions. Candidates must be experienced in building robust and
                                        scalable backend or frontend software.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="single-service-area d-flex mb-100">
                                <div className="icon"> <i className="fa fa-plug" aria-hidden="true"></i></div>
                                <div className="text">
                                    <h5>Hardware</h5>
                                    <p>A talented hardware development professional having expertise in working with
                                        different microcontrollers like AVR, PIC, ARM etc. Should know how different
                                        protocols like USB, UART, SPI, ZIGBEE, I2C, CAN etc work. Basics of digital
                                        electronics and circuit designing. Troubleshooting Skills and testing skills.
                                        Knowledge of various microcontroller architectures including ADC, Timers, Clock
                                        etc. Basic PCB designing and layout along with hands on Simulation Tools.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="single-service-area d-flex mb-100">
                                <div className="icon"> <i className="fa fa-snowflake-o" aria-hidden="true"></i></div>
                                <div className="text">
                                    <h5>Networking</h5>
                                    <p >A talented professional having expertise in
                                        networking. Should Plan, implement, and monitor computer networks. Understand
                                        the technical demands of the business. A comprehensive knowledge of network
                                        protocols and services such as TCP/IP, DNS, and DHCP. Troubleshooting skills of
                                        network functions such as security, servers, and routing</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="contact-area section-padding-100-0 my-5" id="application-form">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-12">
                    <div className="map-area">
                        <div className="contact---area">
                            <div className="contact-form-area contact-page">
                                <h4 className="mb-50">Application Form</h4>
                                <form method="POST" action="/contact-kefi">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group"> <input required type="text" name="name"
                                                    maxLength="50" className="form-control" id="name"
                                                    placeholder="Your Full Name"/></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group"> <input required type="text" pattern="d*"
                                                    maxLength="10" name="phone" className="form-control" id="phone"
                                                    placeholder="Your Phone Number"/></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group"> <input required type="email" name="email"
                                                    maxLength="255" className="form-control" id="email"
                                                    placeholder="Your Email"/></div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group"> <input required type="file" name="cv"
                                                    className="form-control" id="cv"
                                                    accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.rtf,.odf"/>
                                                <p >Upload your
                                                    CV. Allowed types: txt rtf odf pdf doc docx.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group"> <select className="selector" defaultValue={"domain"}>
                                                    <option value="domain" disabled={true}>Domain Applying For</option>
                                                    <option value="software">Software</option>
                                                    <option value="hardware">Hardware</option>
                                                    <option value="networking">Networking</option>
                                                </select></div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group"><textarea required name="details" maxLength="500"
                                                    className="form-control" id="details" cols="30" rows="10"
                                                    placeholder="Message"></textarea></div>
                                        </div>
                                        <div className="col-12"> <button className="btn kefi-btn mt-30"
                                                type="submit">Send</button></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
