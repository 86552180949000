import React from 'react'
// import {  LazyLoadImage } from 'react-lazy-load-image-component';
// import 'react-lazy-load-image-component/src/effects/blur.css';
import { HashLink as Link } from "react-router-hash-link";
 
export default function About() {
  return (
    <div className='mrg-top-all'>
    <section className="breadcrumb-area bg-img bg-overlay jarallax about-paralax"
       >
        <div className="container h-100">
            <div className="row h-100 align-items-center">
                <div className="col-12">
                    <div className="breadcrumb-content">
                        <h2 className=''>About Us</h2>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">About Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="about-area section-padding-100-0" >
        <div className="container">
            <div className="row align-items-center">
                <div className="col-12 col-md-6">
                    <div className="about-content mb-100">
                        <div className="section-heading">
                            <div className="line"></div>
                            <p>KNOW US MORE</p>
                            <h2>About our company</h2>
                        </div>
                        <h6 className="mb-4">A WiFi that binds you to the every corner of the world.</h6>
                        <p className="mb-0">Kefi is a company managed by highly qualified professionals who are from IITs
                            and other reputed engineering institutions having expertise in networking more than 20
                            years.
                            We here at Kefi innovate and develops new wifi technologies that makes easy for user to take
                            control of their network. We are the leading brand that provides best quality, wide range
                            routers at very affordable prices as compared to other companies. <br/> The company has a
                            research team that works very hard to make the connectivity to their routers fast and
                            strong.
                            We are an ISO 9001:2000 certified company.</p>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="about-thumbnail mb-100"> <img  width="100%" height="auto" effect='blur' src="/images/banner/banner_29.webp" alt=""
                            /></div>
                </div>
            </div>
        </div>
    </section>
    <section className="cta-2-area">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="cta-content d-flex flex-wrap align-items-center justify-content-between">
                        <div className="cta-text">
                            <h4>We also take client projects, Do you have one? Get in touch with us.</h4>
                            <p>Quality products, fast delivery & best after sale service</p>
                        </div>
                        <div className="cta-btn"> <Link to="/contact" className="btn kefi-btn box-shadow">Contact Us</Link></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}
