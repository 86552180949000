import React from "react";
import { HashLink as Link } from "react-router-hash-link";
// import "react-lazy-load-image-component/src/effects/blur.css";
// import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Footer() {
  return (
    <>
      <section className="newsletter-area section-padding-100 bg-img jarallax footer-paralax ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-lg-8">
              <div className="nl-content text-center">
                <h2>Subscribe to Kefi</h2>
                <form action="#" method="post">
                  {" "}
                  <input
                    type="email"
                    name="nl-email"
                    id="nlemail"
                    placeholder="Your e-mail"
                  />{" "}
                  <button type="submit">Subscribe</button>
                </form>
                <h6 className="footer-text">
                  {" "}
                  You will be notified and keep informed about new launches or
                  any new information.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid footer-cont justify-content-center">
        <hr className="mx-0 px-0" />
        <footer>
          <div className="row justify-content-around mb-0 pt-5 pb-0 ">
            <div className=" col-11">
              <div className="row justify-content-center">
                <div className="col-md-3 col-12 font-italic align-items-center mt-md-3 mt-4">
                  <h5 className="span-h5">
                    <span>
                      {" "}
                      <img
                        src="/images/kefi_blue.webp"
                        alt="kefi logo"
                        className="img-fluid mb-1 "
                      />
                    </span>
                    
                  </h5>
                  <p className="social mt-md-3 mt-2">
                    {" "}
                    <span>
                    <a href="https://www.amazon.in/stores/page/63EF02AA-074E-4698-93EB-982FC8C028DF" target="_blank"
                        rel="noopener noreferrer"  aria-label="social route">
                      <i
                        className="icon-fa fa fa-amazon "
                      
                      ></i>
                      </a>
                    </span>{" "}
                    
                    <span>
                        <a href="https://www.instagram.com/kefi_mesh_wireless_system/?igshid=MzRlODBiNWFlZA%3D%3D" target="_blank"
                        rel="noopener noreferrer"  aria-label="social route">
                      <i className="fa fa-instagram" >
                      </i>
                        </a>
                    </span>{" "}
                    <span>
                    <a href="https://www.youtube.com/@kefimeshwirelesssystem858" target="_blank"
                        rel="noopener noreferrer"  aria-label="social route">
                      <i className="fa fa-youtube" ></i>
                      </a>
                    </span>{" "}
                  </p>{" "}
                  <small className="copy-rights cursor-pointer">
                    &#9400; 2023 Kefi Konnect{" "}
                  </small>
                  <br />{" "}
                  <small className="copyright">
                    Copyright . All Rights Resered.{" "}
                  </small>
                </div>
                <div className="col-md-3 col-12 my-sm-0 mt-5">
                  <ul className="list-unstyled">
                    <li className="mt-md-3 mt-4">Links</li>
                    <li><Link className="footer-link" to="/about" aria-label="Navigation route">
                      About
                    </Link></li>
                    <li> <Link className="footer-link" to="/working" aria-label="Navigation route">
                      How It Works
                    </Link></li>
                    <li> <Link className="footer-link" to="/career" aria-label="Navigation route">
                      Career
                    </Link></li>
                    <li> <Link className="footer-link" to="/kefiapp" aria-label="Navigation route">
                      {" "}
                      Kefi App
                    </Link></li>
                    <li><Link className="footer-link" to="/kefiadmin" aria-label="Navigation route">
                      Kefi Admin Panel
                    </Link></li>
                    <li> <Link className="footer-link" to="/contact" aria-label="Navigation route">
                      {" "}
                      Contact
                    </Link></li>
                  </ul>
                </div>
                <div className="col-md-3 col-12 my-sm-0 mt-5">
                  <ul className="list-unstyled">
                    <li className="mt-md-3 mt-4">Our Solution</li>
                    <li>Kefi App Support</li>
                    <li>1 Year Warrenty</li>
                    <li>Free Shiping</li>
                    <li>Best In Class Support</li>
                  </ul>
                </div>
                <div className="col-xl-auto col-md-3 col-12 my-sm-0 mt-5">
                  <ul className="list-unstyled text-muted">
                    <li>Donwload App</li>
                    <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.kefi&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                        target="_blank"
                        rel="noopener noreferrer" 
                        aria-label="social route"
                      >
                        <img
                          effect="blur"
                          src="/images/banner/playstore.webp"
                          className=" app-img"
                          width="100"
                          alt="playstore"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://apps.apple.com/us/app/kefi/id1473117839?mt=8"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="social route"
                      >
                        <img
                          effect="blur"
                          src="/images/banner/appstore.webp"
                          className=" app-img"
                          width="100"
                          alt="appstore"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
