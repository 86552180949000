import React, { useEffect, useState } from 'react'
import { HashLink as Link } from "react-router-hash-link";
// import {  LazyLoadImage } from 'react-lazy-load-image-component';
// import 'react-lazy-load-image-component/src/effects/blur.css';
 
export default function Kefiadmin() {
    const[adminId,setAdminId]=useState('manage-id');
    var manage = "View the status of your WiFi, a list of the connected devices, and configure all the features of your router from anywhere anytime through mobile app and cloud based dashboard. Multiple devices can connect to the Kefi system at the same time. The App allows you to monitor their data usage in real time";
    var manageImg="/images/banner/mac-home.webp";
    var qos = "Now you can prioritizes your network traffic anytime. For example, Gaming, Streaming, Surfing, Chatting, Standard and Custom. Thus customize your network speed according to your needs.";
    var qosImg="/images/banner/mac-qos.webp";
    var speed = "Track & Find out your internet speed as provided by your internet service provider.";
    var speedImg="/images/banner/mac-speedtest.webp";
    var parental = "We understand that you need to protect your family from the perils of internet. Kefi allows you to easily manage the time spent online for all the connected devices. You can schedule a time-out for specific devices at certain times.";
    var parentalImg="/images/banner/mac-parental.webp";
    var blacklist = "For us, your protection is of foremost priority. Kefi helps you to blacklist any device that you want not to connect.";
    var blacklistImg="/images/banner/mac-blacklist.webp";
    const [active, setActive] = useState("manage-id");
    useEffect(() => {
    //   console.log(adminId)
    }, [adminId]);
    const clickManage = (event) => {
        setActive(event.target.id);
     
         if(event.target.id==='speed-id'){
           document.getElementById("p-tab").innerHTML = speed;
           document.getElementById("slider_background").setAttribute("src",speedImg);
          }
          else if(event.target.id==='manage-id'){
  
            document.getElementById("p-tab").innerHTML = manage;
            document.getElementById("slider_background").setAttribute("src",manageImg);
          }
          else if(event.target.id==='qos-id'){
  
            document.getElementById("p-tab").innerHTML = qos;
            document.getElementById("slider_background").setAttribute("src",qosImg);
          }
          else if(event.target.id==='blacklist-id'){
  
            document.getElementById("p-tab").innerHTML = blacklist;
           document.getElementById("slider_background").setAttribute("src",blacklistImg);
          }
          else if(event.target.id==='parental-id'){
            document.getElementById("p-tab").innerHTML = parental;
            document.getElementById("slider_background").setAttribute("src",parentalImg);
          }
        setAdminId(event.target.id);
  
    
      }
  return (
    <>
        <section className="breadcrumb-area bg-img bg-overlay jarallax admin-paralax mrg-top-all">
        <div className="container h-100">
            <div className="row h-100 align-items-center">
                <div className="col-12">
                    <div className="breadcrumb-content">
                        <h2>Apps & Services</h2>0
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item" >
                                    Apps & Services</li>
                                <li className="breadcrumb-item active" aria-current="page">Kefi Admin Panel</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="about-area section-padding-100-0">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-12 col-md-6 ">
                    <div className="about-content mb-100">
                        <div className="section-heading">
                            <div className="line"></div>
                            <p>A cloud based dashboard</p>
                            <h2>Introducing our Kefi Admin Panel</h2>
                        </div>
                        <h6 className="mb-4">Control your Home's Network while sitting anywhere in the world with our newly
                            developed dashboard</h6>
                        <div className="kefi-tabs-content ">
                            <ul className="nav nav-tabs" id="myTabMac" >
                                <li className="nav-item">
                                    <div className="scf-icon mr-15 tab-icon"> <i id="manage_icon_mac"
                                            className="fa fa-home mac-tab-icon-manage mac-tab-icon-active"
                                            ></i></div> <button onClick={clickManage}
                                            className={active==="manage-id"?"nav-link active":"nav-link"} id="manage-id" data-toggle="tab" 
                                         aria-controls="tabmac1" >Manage</button>
                                </li>
                                <li className="nav-item">
                                    <div className="scf-icon mr-15 tab-icon"> <i id="parental_icon_mac"
                                            className="fa fa-child mac-tab-icon-parental" ></i>
                                    </div> <button onClick={clickManage} className={active==="parental-id"?"nav-link active":"nav-link"} id="parental-id"
                                        data-toggle="tab"   aria-controls="tabmac2"
                                        >Parental</button>
                                </li>
                                <li className="nav-item">
                                    <div className="scf-icon mr-15 tab-icon"> <i id="speedtest_icon_mac"
                                            className="fa fa-tachometer mac-tab-icon-speedtest"
                                            ></i></div> <button onClick={clickManage}
                                            className={active==="speed-id"?"nav-link active":"nav-link"} id="speed-id" data-toggle="tab"  
                                        aria-controls="tabmac3" >Speed Test</button>
                                </li>
                                <li className="nav-item">
                                    <div className="scf-icon mr-15 tab-icon"> <i id="qos_icon_mac"
                                            className="fa fa-bar-chart mac-tab-icon-qos" ></i></div>
                                    <button onClick={clickManage} className={active==="qos-id"?"nav-link active":"nav-link"} id="qos-id" data-toggle="tab"
                                          aria-controls="tabmac4" >QoS</button>
                                </li>
                                <li className="nav-item">
                                    <div className="scf-icon mr-15 tab-icon"> <i id="blacklist_icon_mac"
                                            className="fa fa-user-times mac-tab-icon-blacklist"
                                            ></i></div> <button onClick={clickManage}
                                            className={active==="blacklist-id"?"nav-link active":"nav-link"} id="blacklist-id" data-toggle="tab"  
                                        aria-controls="tabmac5" >Blacklist</button>
                                </li>
                            </ul>
                            <div className="col-12 col-md-12 tab-content mb-100" id="myTabContent">
                                <div className="tab-pane fade show active" id="tabmac1"
                                    aria-labelledby="tab---1">
                                    <div className="kefi-tab-content">
                                        <div className="kefi-tab-text">
                                            <p id="p-tab">View the status of your WiFi, a list of the
                                                connected devices, and configure all the features of
                                                your router from anywhere anytime through mobile app and cloud based
                                                dashboard.
                                                Multiple devices can connect to the Kefi system at the same time. The
                                                App allows you to
                                                monitor their data usage in real time.</p>
                                        </div>
                                    </div>
                                </div>   
                                 <a href="https://dashboard.kefikonnect.com/" target="_blank" rel="noopener noreferrer"
                                    className="btn kefi-btn mt-50"><i className="fa fa-sign-in"
                                        
                                        aria-hidden="true"></i> Login</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 " >
                    <div className="about-thumbnail mb-100">
                        <div className="css-mb">
                            <div className="mb-display-position">
                                <div className="mb-display">
                                    <div className="mb-screen-position">
                                        <div className="mb-screen"> <img effect='blur' id="slider_background"
                                             width="100%" height="auto"   src="/images/banner/mac-home.webp" alt="Kefi app images" /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-body"></div>
                            <div className="mb-bottom-cover"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="team-area section-padding-100-0 ">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="section-heading text-center mb-100">
                        <div className="line"></div>
                        <p>Not just app, there is more</p>
                        <h2>Login to admin panel to manage your kefi units</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-12">
                    <div className="single-team-member-area mb-100">
                        <div className="css-mb with-glare">
                            <div className="mb-display-position">
                                <div className="mb-display">
                                    <div className="mb-screen-position">
                                        <div className="mb-screen"> <img effect='blur' width="100%" height="auto" src="/images/banner/mac-login.webp"
                                                alt="Laptop Stencil" /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-body"></div>
                            <div className="mb-bottom-cover"></div>
                        </div>
                        <div className="team-info"> <br/>
                            <h6>Login credentials will be provided to users on purchase of a kefi unit.</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="team-area section-padding-100-0 ">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="section-heading text-center mb-100">
                        <div className="line"></div>
                        <p>There is lot more</p>
                        <h2>Parental Control | Network Management | Speed Test</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="single-team-member-area mb-100">
                        <div className="css-mb with-glare">
                            <div className="mb-display-position">
                                <div className="mb-display">
                                    <div className="mb-screen-position">
                                        <div className="mb-screen"> <img effect='blur' width="100%" height="auto" src="/images/banner/mac-parental.webp"
                                                alt="Laptop Stencil" /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-body"></div>
                            <div className="mb-bottom-cover"></div>
                        </div>
                        <div className="team-info"> <br/>
                            <h5>Parental Control</h5>
                            <h6>Kefi allows parents to set controls for their children's internet use</h6>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="single-team-member-area mb-100">
                        <div className="css-mb with-glare">
                            <div className="mb-display-position">
                                <div className="mb-display">
                                    <div className="mb-screen-position">
                                        <div className="mb-screen"> <img effect='blur' width="100%" height="auto" src="/images/banner/mac-network.webp"
                                                alt="Laptop Stencil" /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-body"></div>
                            <div className="mb-bottom-cover"></div>
                        </div>
                        <div className="team-info"> <br/>
                            <h5>Multiple Network Management</h5>
                            <h6>Kefi allows their users to switch to any network at anytime</h6>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="single-team-member-area mb-100">
                        <div className="css-mb with-glare">
                            <div className="mb-display-position">
                                <div className="mb-display">
                                    <div className="mb-screen-position">
                                        <div className="mb-screen"> <img effect='blur' width="100%" height="auto" src="/images/banner/mac-speedtest.webp"
                                                alt="Laptop Stencil" /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-body"></div>
                            <div className="mb-bottom-cover"></div>
                        </div>
                        <div className="team-info"> <br/>
                            <h5>Internet Speed Test</h5>
                            <h6>Track your internet speed as provided by your internet provider</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
     
    </>
  )
}
