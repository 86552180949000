import React from "react";
import { HashLink as Link } from "react-router-hash-link";

export default function Navbar() {

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
        <div className="container-fluid" >
          <Link className="navbar-brand fw-bold" to="/">
            <img
              className="imgg kefi-blue"
              src="/images/kefi.png"
              alt="logo"
              width="90"
              height="720"
              data-target="#sidenav-collapse-main" data-toggle="collapse"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse menu"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item" data-target="#sidenav-collapse-main" data-toggle="collapse">
                <Link className="nav-link active"   to="/" data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse" >
                <span data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse">Home</span>
                </Link>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Apps & Services
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li data-target="#sidenav-collapse-main" data-toggle="collapse">
                    <Link className="dropdown-item" to="/kefiapp" data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse" >
                      <span data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse">Kefi App</span>
                    </Link>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li data-target="#sidenav-collapse-main" data-toggle="collapse">
                    <Link className="dropdown-item" to="/kefiadmin" data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse" >
                      <span data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse">Kefi Admin Panel</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item" data-target="#sidenav-collapse-main" data-toggle="collapse">
                <Link className="nav-link" to="/working" data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse" >
                <span data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse">How It Work</span> 
                </Link>
              </li>
              <li className="nav-item" data-target="#sidenav-collapse-main" data-toggle="collapse">
                <Link className="nav-link" to="/about" data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse" >
                <span data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse">About</span> 
                </Link>
              </li>
              <li className="nav-item" data-target="#sidenav-collapse-main" data-toggle="collapse">
                <Link className="nav-link" to="/career" data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse" >
                <span data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse">Career</span> 
                </Link>
              </li>
            </ul>
            <Link className="btn btn-outline-light border rounded-0 cont-left" type="submit" to="/contact" data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse" >
            <span data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse">Contact</span> 
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
}
